@use "sass:math";

////
/// shared mixins
/// @group mixins
////

///
/// This mixin is just a facade for font-size/line-height maps using rem unit.
///
/// For our legacy font-size/line-height maps with px units it returns
/// a font-size with rem-value and line-height with em-value.
///
/// @param {map} font Includes font-size and line-height.
/// @param {boolean} line-height [true] Indicates if line-height should be omitted.
///
@mixin font-size($font, $line-height: true) {
  $fontSize: (map-get($font, font-size));
  @if unit($fontSize) == rem {
    font-size: $fontSize;
  } @else if unit($fontSize) == px {
    // TODO: can be dropped once legacy fonts are EoL
    font-size: (math.div($fontSize, 16px) + rem);
  } @else {
    @error "Inalid font-size unit: '#{$fontSize}'.";
  }
  $lineHeight: map-get($font, line-height);
  @if $line-height == true and $lineHeight != "" {
    @if unitless($lineHeight) {
      line-height: $lineHeight;
    } @else if unit($lineHeight) == px {
      // TODO: can be dropped once legacy fonts are EoL
      line-height: math.div($lineHeight, $fontSize) + em;
    } @else {
      @error "Invalid line-height unit: '#{$lineHeight}'.";
    }
  }
}
