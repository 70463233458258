@import "@otto-ec/global-frontend-settings/scss/grid";
@import "@otto-ec/global-frontend-settings/scss/font-size";
@import "@otto-ec/global-frontend-settings/scss/_variables";
@import "@otto-ec/global-frontend-settings/scss/_pl_variables";

.wo_avContent {

  #wo_av_sidebar {
    .wo_caption {
      @include font-size($font100);
      text-align: right;
      color: #212121;
      font-weight: bold;
      margin-bottom: 0;
    }

    .wo_avInnerWrapper {
      float: right;
    }
  }

  #wo_av_bottombar {
    text-align: center;
    padding: 0 8px;

    .wo_caption {
      display: none;
    }

    @media (min-width: #{$large-width}) {
      display: none;
    }

    iframe {
      border: 1px solid $grey200;
    }
  }

  &#san_av_bottom #wo_av_bottombar {
    &.wo_marginTop {
      margin-top: 24px;
    }
  }
}


[data-contentarea-id="Storefront"] .wo_avContent #wo_av_bottombar.wo_marginBottom {
  margin-bottom: 24px;
}
